import StripSlideshowComponent from '@wix/thunderbolt-elements/src/components/StripSlideshow/viewer/StripSlideshow';
import StripSlideshowController from '@wix/thunderbolt-elements/src/components/StripSlideshow/viewer/StripSlideshow.controller';


const StripSlideshow = {
  component: StripSlideshowComponent,
  controller: StripSlideshowController
};


export const components = {
  ['StripSlideshow']: StripSlideshow
};


// temporary export
export const version = "1.0.0"
